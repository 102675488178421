import { css } from '@emotion/react/macro'
import { PAYPAL_THEME } from '@paypalcorp/pp-react'

const paypalOpenRegular = `PayPalOpen-Regular`
const paypalOpenBold = `PayPalOpen-Bold`
const paypalOpenMedium = `PayPalOpen-Medium`
const paypalPro = 'PayPalPro-Black'

const borderRadius = '0.5rem'
const borderRadiusSmall = '0.25rem'

const colors = {
  primary: {
    bluemidnight: '#0070BA',
    bluemedium: '#057FE5',
    bluelight: '#55AFFB',
    black: '#000000',
    white: '#ffffff',
    red: '#C1281B',
    green: '#4D9678',
  },
  secondary: {
    greymidnight: '#687173',
    greydark: '#6B6767',
    greymedium: '#808080',
    greylight: '#E6E6E6',
    offwhite: '#FCFCFC',
  },
}

const breakpointData = {
  breakpoints: [
    { value: 36, unit: 'rem' }, //sm
    { value: 47, unit: 'rem' }, //md
    { value: 64, unit: 'rem' }, //lg
    { value: 72, unit: 'rem' }, //xl
  ],
  margins: [
    { value: 24, unit: 'px' },
    { value: 24, unit: 'px' },
    { value: 36, unit: 'px' },
    { value: 36, unit: 'px' },
    { value: 36, unit: 'px' },
  ],
  maxWidth: { value: 1192, unit: 'px' },
}

const breakpoints = breakpointData.breakpoints.map(
  (bp) => `${bp.value}${bp.unit}`,
)

breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

const spaceData = [...Array(16).keys()].map((i) => ({
  value: i * 8,
  unit: 'px',
}))
const space = spaceData.map((sp) => `${sp.value / 16}rem`)
space.forEach((v, i) => {
  if (i > 0) {
    space[`x${i}`] = v
    spaceData[`x${i}`] = spaceData[i]
  }
})

const buttonHoverDefault = {
  textDecoration: 'none',
  cursor: 'pointer',
}

const buttonDefault = {
  fontFamily: paypalOpenBold,
  fontSize: '1rem',
  fontWeight: '500',
  lineHeight: '1',
  letterSpacing: '-.01em',
  borderWidth: '2px',
  borderStyle: 'solid',
  outline: 'none',
  whiteSpace: 'nowrap',
  padding: '0.75rem 2rem',
}

const buttonSmall = {
  fontFamily: paypalOpenBold,
  borderWidth: '2px',
  borderStyle: 'solid',
  fontSize: '.875rem',
  fontWeight: 'normal',
  lineHeight: 'inherit',
  letterSpacing: '-.01em',
  whiteSpace: 'nowrap',
  outline: 'none',
  padding: '0.5rem 2rem',
}

const buttonCalloutCta = {
  fontFamily: paypalOpenBold,
  borderWidth: '2px',
  borderStyle: 'solid',
  fontSize: '1.125rem',
  fontWeight: 'normal',
  lineHeight: 'inherit',
  letterSpacing: '-.01em',
  whiteSpace: 'nowrap',
  outline: 'none',
  padding: '0.5rem 2.5rem',
}

const inViewClass = `in-view`

const inViewOptions = {
  translateY: '20px',
  delay: 0,
  parent: false,
}

const inViewStyle = (options = inViewOptions) => {
  return css`
    opacity: 0;
    transform: translateY(${options.translateY});
    transition: 0.3s ease-out;
    transition-delay: ${options.delay}ms;
    transition-property: transform, opacity, box-shadow;

    ${options.parent ? `.${inViewClass} &` : `&.${inViewClass}`} {
      opacity: 1;
      transform: translateY(0);
    }
  `
}

const stntheme = {
  ...PAYPAL_THEME,
  borderRadius,
  borderRadiusSmall,
  colors,
  breakpoints,
  breakpointData,
  space,
  spaceData,
  inViewClass,
  inViewStyle,
  textStyles: {
    hero: {
      fontFamily: `${paypalPro}, ${paypalOpenBold}`,
      fontSize: ['2.5rem', '3rem'],
      fontWeight: '500',
      lineHeight: '1',
      letterSpacing: '-.04em',
      textAlign: 'center',
      color: PAYPAL_THEME.sysColorPrimaryContrast,
    },
    h1: {
      fontFamily: `${paypalPro}, ${paypalOpenBold}`,
      fontSize: ['2.5rem', '3.75rem'],
      fontWeight: '500',
      lineHeight: '1',
      letterSpacing: '-.04em',
    },
    h2: {
      fontFamily: `${paypalPro}, ${paypalOpenBold}`,
      fontSize: '2rem',
      fontWeight: 'normal',
      lineHeight: '1',
      letterSpacing: '-.04em',
    },
    h3: {
      fontFamily: paypalOpenBold,
      fontSize: '1.75rem',
      fontWeight: '500',
      lineHeight: '1',
      letterSpacing: '-.04em',
    },
    h4: {
      fontFamily: paypalOpenRegular,
      fontSize: '1.1rem',
      fontWeight: 'normal',
      lineHeight: '1.2',
      letterSpacing: '0',
    },
    h5: {
      fontFamily: paypalOpenMedium,
      fontSize: '1.1rem',
      fontWeight: 'normal',
      lineHeight: '1',
      letterSpacing: '0',
    },
    p1: {
      fontFamily: paypalOpenRegular,
      fontSize: '1.06rem',
      fontWeight: '300',
      lineHeight: '1.6',
      letterSpacing: '-.01em',
      '& strong': {
        fontWeight: '600',
        color: colors.primary.bluemidnight,
      },
    },
    p2: {
      fontFamily: paypalOpenRegular,
      fontSize: '0.875rem',
      fontWeight: 'normal',
      lineHeight: '1.45',
      letterSpacing: '0em',
    },
    eyebrow: {
      fontFamily: paypalOpenRegular,
      fontSize: '.875rem',
      fontWeight: 'normal',
      lineHeight: '1',
      textTransform: 'uppercase',
      color: colors.secondary.greymidnight,
    },
    eyebrow2: {
      fontFamily: paypalOpenRegular,
      fontSize: '.75rem',
      fontWeight: 'normal',
      lineHeight: '1',
      textTransform: 'uppercase',
      color: colors.secondary.greymidnight,
    },
    cardTitle: {
      fontFamily: paypalOpenRegular,
      fontSize: '.875rem',
      fontWeight: 'normal',
      lineHeight: '1.3',
      color: colors.secondary.greymidnight,
    },
    listItemName: {
      fontFamily: paypalOpenRegular,
      fontSize: '1rem',
      letterSpacing: '-.01em',
      lineHeight: '1',
      fontWeight: 'normal',
    },
    listItemDescription: {
      fontFamily: paypalOpenRegular,
      fontSize: '.875rem',
      fontWeight: '300',
      lineHeight: '1.3',
      letterSpacing: '-.01em',
    },
    listItemEyebrow: {
      fontFamily: paypalOpenRegular,
      fontSize: '.625rem',
      fontWeight: 'normal',
      lineHeight: '1',
      textTransform: 'uppercase',
      color: colors.secondary.greymidnight,
    },
    promoHeader: {
      fontFamily: paypalOpenBold,
      fontWeight: '500',
      fontSize: ['2.625rem', '3.125rem'],
      lineHeight: '1',
      span: {
        color: colors.primary.bluemidnight,
      },
    },
    guideDescription: {
      fontFamily: paypalOpenRegular,
      fontSize: '1.06rem',
      fontWeight: '300',
      lineHeight: '1.4',
      letterSpacing: '-.01em',
    },
    link: {
      fontFamily: paypalOpenRegular,
      fontSize: '1rem',
      fontWeight: 'normal',
      lineHeight: '1.3',
      letterSpacing: '-.01em',
      textDecoration: 'underline',
      color: colors.primary.bluemidnight,
    },
    ticker: {
      fontFamily: paypalOpenMedium,
      fontSize: '.875rem',
      fontWeight: 'normal',
      lineHeight: '1.2',
      letterSpacing: '-.01em',
    },
    subnav: {
      fontFamily: paypalOpenRegular,
      fontWeight: 'normal',
      fontSize: '1rem',
      lineHeight: '1',
      letterSpacing: '0',
    },
    notification: {
      fontFamily: paypalOpenRegular,
      fontSize: '.875rem',
      fontWeight: 'normal',
      lineHeight: '1',
      letterSpacing: '-.01em',
    },
    deal: {
      fontFamily: paypalOpenRegular,
      fontSize: '.875rem',
      fontWeight: 'normal',
      lineHeight: '1.5',
      letterSpacing: '-.01em',
    },
    caption: {
      fontFamily: paypalOpenRegular,
      fontSize: '1.25rem',
      fontWeight: 'normal',
      lineHeight: '1.3',
      letterSpacing: '-.01em',
    },
    smallCaption: {
      fontFamily: paypalOpenRegular,
      fontSize: '0.75rem',
      fontWeight: 'normal',
      lineHeight: '1',
      letterSpacing: '.01em',
    },
  },
  buttons: {
    primary: {
      ...buttonDefault,
      backgroundColor: colors.primary.bluemidnight,
      color: colors.primary.white,
      borderColor: 'currentColor',
      '&:hover': {
        ...buttonHoverDefault,
        color: colors.primary.bluemidnight,
        borderColor: colors.primary.bluemidnight,
        backgroundColor: colors.primary.white,
      },
    },
    primaryInverse: {
      ...buttonDefault,
      backgroundColor: 'transparent',
      color: colors.primary.white,
      borderColor: colors.primary.white,
      '&:hover': {
        ...buttonHoverDefault,
        color: colors.primary.bluemidnight,
        borderColor: colors.primary.white,
        backgroundColor: colors.primary.white,
      },
    },
    secondary: {
      ...buttonDefault,
      color: colors.primary.white,
      backgroundColor: colors.primary.bluemidnight,
      borderColor: 'transparent',
      '&:hover': {
        ...buttonHoverDefault,
        color: colors.primary.white,
        backgroundColor: colors.primary.bluemedium,
      },
    },
    small: {
      ...buttonSmall,
      backgroundColor: 'transparent',
      color: colors.primary.bluemidnight,
      border: 'none',
      whiteSpace: 'nowrap',
      minWidth: 0,
      lineHeight: 1,
      fontSize: '16px',
      '&:hover': {
        ...buttonHoverDefault,
        color: colors.primary.bluemidnight,
        border: 'none',
        backgroundColor: 'transparent',
        textDecoration: 'underline',
      },
    },
    smallInverse: {
      ...buttonSmall,
      backgroundColor: 'transparent',
      color: colors.primary.bluemidnight,
      borderColor: 'currentColor',
      whiteSpace: 'nowrap',
      minWidth: 0,
      '&:hover': {
        ...buttonHoverDefault,
        color: colors.primary.bluemedium,
        borderColor: colors.primary.bluemedium,
      },
    },
    smallSecondary: {
      ...buttonSmall,
      color: colors.primary.white,
      backgroundColor: colors.primary.bluemidnight,
      padding: '0.25rem 1.25rem',
      borderColor: 'transparent',
      minWidth: 0,
      '&:hover': {
        ...buttonHoverDefault,
        backgroundColor: colors.primary.bluemedium,
      },
    },
    calloutCta: {
      ...buttonCalloutCta,
      color: colors.primary.white,
      backgroundColor: colors.primary.bluemidnight,
      borderColor: 'transparent',
      '&:hover': {
        ...buttonHoverDefault,
        color: colors.primary.white,
        backgroundColor: colors.primary.bluemedium,
      },
    },
    filter: {
      fontFamily: paypalOpenRegular,
      borderWidth: '1px',
      borderStyle: 'solid',
      fontSize: '.875rem',
      fontWeight: '600',
      lineHeight: '1',
      whiteSpace: 'nowrap',
      outline: 'none',
      padding: '0.5rem 2rem',
      color: colors.primary.bluemidnight,
      borderColor: colors.primary.bluemidnight,
      backgroundColor: 'white',
      boxShadow: '0 0 0 1px rgba(0,0,0,0.15)',
      '&:hover': {
        color: colors.primary.bluemidnight,
        boxShadow: `0 0 0 1px ${colors.primary.bluemidnight}`,
        backgroundColor: colors.primary.white,
      },
    },
    price: {
      fontFamily: paypalOpenRegular,
      borderWidth: '1px',
      borderStyle: 'solid',
      backgroundColor: 'white',
      fontSize: '1.0625rem',
      fontWeight: 'normal',
      lineHeight: 'inherit',
      whiteSpace: 'nowrap',
      outline: 'none',
      padding: '0.5rem 0',
      borderRadius: '5px',
      minWidth: 'calc(25% - 0.5rem)',
      color: colors.secondary.greymidnight,
      marginRight: '0.5rem',

      '&:hover': {
        color: colors.primary.bluemidnight,
      },
    },
  },
}

export { stntheme as default }
