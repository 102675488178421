import React, { Fragment, useContext } from 'react'
import PropTypes from 'prop-types'
import { MessageFormat } from '@paypalcorp/worldready'
import {
  Message,
  Context as WorldReadyContext,
} from '@paypalcorp/worldready-react'
import { useQuery } from '@apollo/client'
import { Redirect } from 'react-router-dom'

import getHomepageData from '../../graphql/getHomepageData'
import getAllProducts from '../../graphql/getAllProducts'
import { Box } from '../../components/box'
import { Carousel } from '../../components/carousel'
import { Container, Row, Col } from '../../components/grid'
import { GiftCard } from '../../components/gift-card'
import { GuideCard } from '../../components/guide-card'
import { HeroHeader } from '../../components/hero-header'
import { Loader } from '../../components/loader'
import { LogoBubble } from '../../components/logo-bubble'
import { PromoCard } from '../../components/promo-card'
import { VerticalGiftListWithData } from '../../components/vertical-gift-list'
import { TitleTicker } from '../../components/title-ticker'
import { useTrackImpression } from '../../utils/hooks/useTrackImpression'
import { handleSessionError } from '../../utils/error-tracking'
import { isNotBundle } from '../../utils/cardFilters'
import clientData from '../../utils/client-data'
import { HomePageSection } from './HomePageSection'
import { giftGuides } from '../../gift-guide-routes'
import brands from './HomePageBrands'
import { Text } from '../../components/text'

const elmo = clientData.experimentation
const country = clientData.locality.country

const heroMedia = {
  lg: 'https://www.paypalobjects.com/gifts/assets/hero-headers/home_hero_2025_lg.png',
  sm: 'https://www.paypalobjects.com/gifts/assets/hero-headers/home_hero_2025_sm.png',
}

const HomePageContent = ({ pageData }) => {
  const { giftCards, guides, lists, promos } = pageData

  const worldready = useContext(WorldReadyContext)
  const { data, loading, error } = useQuery(getHomepageData, {
    variables: { urlKey: 'categories/popular' },
  })
  // prefetch getAllProducts for caching
  useQuery(getAllProducts)
  useTrackImpression(
    'main:consumer:gifts:home:',
    'main:consumer:gifts:home::::',
  )

  if (loading) return <Loader py="100px" />

  if (error) {
    handleSessionError(error)
    return <Redirect to="/error" />
  }

  const popularGifts = data.getProductsInCategory.all_a_z
  const popularCards = popularGifts.filter(isNotBundle)

  const { deals } = data.getLandingData

  return (
    <Fragment>
      {popularCards && !!popularCards.length && (
        <HomePageSection section={giftCards}>
          {popularCards.map((card, i) => (
            <Col md={3} key={`popular-gift-card-${i}`} mb="x4">
              <GiftCard
                url={card.url_key}
                img={card.img_url}
                label={card.name}
                name="GiftCard"
                data-pagename="main:consumer:gifts:home:"
                data-pagename2={`main:consumer:gifts:home:card:${card.url_key}::`}
              />
            </Col>
          ))}
        </HomePageSection>
      )}

      {elmo.v2guides ? (
        <HomePageSection section={guides} data-testid="gift-guides-section">
          <Carousel
            items={guides.cards.map((card, i) => (
              <Box width="300px" key={`card-box-${i}`}>
                <GuideCard
                  card={card}
                  data-pagename="main:consumer:gifts:home:"
                  data-pagename2="main:consumer:gifts:home:guidecard:::"
                />
              </Box>
            ))}
          />
        </HomePageSection>
      ) : null}
      {country === 'US' && (
        <Container>
          <Row my="1" align="center">
            <Text
              as="h2"
              color="secondary.greydark"
              dangerouslySetInnerHTML={{
                __html: new MessageFormat(worldready, {
                  id: 'pages/home.disclaimer.scams',
                }).format(),
              }}
            />
          </Row>
        </Container>
      )}
      <HomePageSection section={brands} data-testid="top-brands-section">
        <Carousel
          items={brands.cards.map((logo, i) => (
            <Box width="150px" height="150px" key={`brand-box-${i}`}>
              <LogoBubble
                alt={logo.name}
                imgUrl={logo.img_url}
                url={`/${logo.url_key}`}
                name="TopBrand"
                data-pagename="main:consumer:gifts:home:"
                data-pagename2={`main:consumer:gifts:home:topbrand:${logo.url_key
                  .toLowerCase()
                  .replace(/[^A-Za-z]/g, '-')}::`}
              />
            </Box>
          ))}
        />
      </HomePageSection>
      {deals && !!deals.length ? (
        <HomePageSection data-testid="deals-section" section={promos} noEyebrow>
          <Carousel
            items={deals.map((deal, i) => (
              <PromoCard
                key={i}
                title={deal.name}
                smImg={deal.mobile_banner_url}
                lgImg={deal.banner_url || deal.img_url}
                href={deal.url_key}
                terms={deal.terms}
                name="SeeDeal"
                data-pagename="main:consumer:gifts:home:"
                data-pagename2={`main:consumer:gifts:home:deal:${deal.url_key}::`}
                isMarketingPromo={deal.is_marketing_promotion}
              />
            ))}
          />
        </HomePageSection>
      ) : null}
      <Container as="section" data-testid="vertical-lists-section">
        <Row>
          <Col md={12} lg={6}>
            <VerticalGiftListWithData
              name={lists.cards.name}
              category={lists.cards.category}
              cardStyle="cards"
              maxNumItems={5}
            />
          </Col>

          <Col md={12} lg={6}>
            <VerticalGiftListWithData
              name={lists.cards2.name}
              category={lists.cards2.category}
              cardStyle="cards"
              maxNumItems={5}
            />
          </Col>
        </Row>
      </Container>
      {country === 'GB' && (
        <Container>
          <Row my="x3">
            <Text as="h3" color="secondary.greydark">
              <Message id="pages/home.disclaimer.regulation" />
            </Text>
          </Row>
        </Container>
      )}
    </Fragment>
  )
}

const HomePage = () => {
  const worldready = useContext(WorldReadyContext)
  const textItems = new MessageFormat(worldready, {
    id: 'pages/home.hero.title.items',
  }).format()
  const suggestions = new MessageFormat(worldready, {
    id: 'pages/suggestions.suggestions.all',
  }).format()

  return (
    <Fragment>
      <HeroHeader
        headerStyle="search"
        headlineComponent={
          <TitleTicker textItems={textItems.split('|')} delay={4000} />
        }
        searchSuggestions={suggestions ? suggestions.split(',') : []}
        mediaSrc={heroMedia}
        isHeroExpanded
      />
      <HomePageContent />
    </Fragment>
  )
}

HomePageContent.propTypes = {
  pageData: PropTypes.object,
}

HomePageContent.defaultProps = {
  pageData: {
    giftCards: {
      name: 'cards',
      ctaHref: '/all',
    },
    guides: {
      name: 'guides',
      ctaHref: '/gift-guides',
      cards: giftGuides,
    },
    lists: {
      cards: {
        name: 'cardsList',
        category: {
          US: 'categories/travel-and-leisure',
          GB: 'categories/gaming',
          CA: 'categories/dining-and-food',
          AU: 'occasions/just-because',
          DE: 'occasions/jahrestag',
          AT: 'categories/shopping',
        }[country],
      },
      cards2: {
        name: 'cardsList',
        category: {
          US: 'categories/sports-and-outdoor',
          GB: 'occasions/birthday',
          CA: 'categories/gifts-and-variety',
          AU: 'occasions/birthday',
          DE: 'occasions/geburtstag',
          AT: 'categories/entertainment',
        }[country],
      },
    },
    promos: {
      name: 'deals',
    },
  },
}

export default HomePage
