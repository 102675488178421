import React from 'react'
import styled from '@emotion/styled/macro'
import {
  useHistory,
  useLocation,
  useRouteMatch,
  Redirect,
} from 'react-router-dom'
import { Box } from '../../components/box'
import { Text } from '../../components/text'
import { Loader } from '../../components/loader'
import { Container, Row, Col } from '../../components/grid'
import { SearchInput } from '../../components/search-bar'
import { isNotBundle, isSMB } from '../../utils/cardFilters'
import { trackImpression } from '../../utils/analytics'
import { handleSessionError } from '../../utils/error-tracking'
import { Message } from '@paypalcorp/worldready-react'
import { ResultsHeader } from './ResultsHeader'
import { FilteredItemList, CardItem } from './FilteredItemList'
import useAllProductsSearch from './useAllProductsSearch'

const TYPE_ALL = 'all'
const TYPE_CARDS = 'cards'
const TYPE_SMBS = 'businesses'
const ALL_TYPES = new Set([TYPE_ALL, TYPE_CARDS, TYPE_SMBS])

const SEARCH_FILTERS = [
  /^the$/gi,
  /^and$/gi,
  /^with$/gi,
  /^gifts?$/gi,
  /^codes?$/gi,
  /^cards?$/gi,
  /^geschenkkarten$/gi,
  /^gutscheincode$/gi,
]

const filterSearchTerms = (searchQuery) =>
  searchQuery
    .split(/\s+/)
    .filter((word) => !SEARCH_FILTERS.some((filter) => filter.test(word)))
    .join(' ')

const SearchResults = () => {
  const location = useLocation()
  const history = useHistory()
  const match = useRouteMatch()

  const queryParams = new URLSearchParams(location.search)
  const searchQuery = queryParams.get('q') || ''
  const filteredSearchQuery = filterSearchTerms(searchQuery)

  const { products, loading, error } = useAllProductsSearch(filteredSearchQuery)

  const foundMatch = ALL_TYPES.has(match.params.type)
  const resultsType = foundMatch ? match.params.type : TYPE_ALL

  const handleSearchSubmit = (value) => {
    const urlQuery =
      value && value.length > 0 ? `?q=${encodeURIComponent(value)}` : ''
    history.push(`/search/${TYPE_ALL}${urlQuery}`)
  }

  const SearchRow = styled(Row)`
    position: sticky;
    top: 64px;
    align-self: flex-start;
    z-index: 989;
    background: white;
  `

  const searchComponents = (
    <SearchRow pt="2" pb="1.5rem">
      <Col xs={12} md={6} lg={8}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%"
        >
          <SearchInput value={searchQuery} onSubmit={handleSearchSubmit} />
        </Box>
      </Col>
    </SearchRow>
  )

  if (error) {
    handleSessionError(error)
    return <Redirect to="/error" />
  }

  if (loading) {
    return (
      <Container>
        <Box pb="4">{searchComponents}</Box>
        <Loader py="100px" />
      </Container>
    )
  }

  const filteredCards = products.filter(isNotBundle)
  const filteredSMBs = products.filter(isSMB)

  if (searchQuery.length >= 2) {
    trackImpression(
      'main:consumer:gifts:search:',
      `main:consumer:gifts:search:${resultsType}:::`,
      { srch: searchQuery },
    )
  }

  let resultsCount = filteredCards.length
  if (resultsType === TYPE_CARDS) resultsCount = filteredCards.length
  if (resultsType === TYPE_SMBS) resultsCount = filteredSMBs.length

  return (
    <Container>
      {searchComponents}

      {!!searchQuery && (
        <ResultsHeader>
          <Text>
            <Message
              id="pages/search.search.countString"
              count={resultsCount}
              query={searchQuery}
            />
          </Text>
        </ResultsHeader>
      )}

      <FilteredItemList
        show={resultsType === TYPE_ALL || resultsType === TYPE_CARDS}
        filteredItems={filteredCards}
        headerMessageID="pages/search.search.cardsHeader"
        Item={CardItem}
      />

      <FilteredItemList
        show={resultsType === TYPE_SMBS}
        filteredItems={filteredSMBs}
        headerMessageID="pages/search.search.cardsHeader"
        Item={CardItem}
      />
    </Container>
  )
}

export default SearchResults
