const GIFT_BUNDLES = 'Gift Bundles'
const MINI_BUNDLES = 'Mini Bundles'

export const isSMB = (item) => {
  const SMB_CATEGORY = /^Small Business$/i
  const categories = item.categories || []
  return categories.some((category) => SMB_CATEGORY.test(category.trim()))
}

export function isPopular(item) {
  // FIXME l10n for other languages
  const regexp = /(popular|populaires|beliebtesten)/i
  return item.categories.some((category) => regexp.test(category))
}

export function isNotBundle(items) {
  return !items.categories.some((category) =>
    [GIFT_BUNDLES, MINI_BUNDLES].includes(category),
  )
}
