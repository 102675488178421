import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useInView } from 'react-hook-inview'
import { useTheme } from '@emotion/react/macro'

import * as Styled from './VerticalGiftList.styled'

import { ImageLazy } from '../image-lazy'
import { Text } from '../text'
import { Box } from '../box'
import { SectionIntro } from '../section-intro'
import { useCurrentBreakpoint } from '../../utils/hooks/useCurrentBreakpoint'

const stripHtml = (html) => {
  try {
    const doc = new DOMParser().parseFromString(html, 'text/html')
    return doc.body.textContent || html
  } catch (e) {
    return html
  }
}

const ellipsify = (text, maxLength = 80, ellipsis = '…') => {
  if (text.trim().length <= maxLength) return text.trim()
  let hasEllipsis = false
  return text
    .trim()
    .split(' ')
    .reduce((combined, word) => {
      if (hasEllipsis) return combined
      if (combined.length + word.length + ellipsis.length + 2 > maxLength) {
        hasEllipsis = true
        return combined + ' ' + ellipsis
      }
      return combined + ' ' + word
    }, '')
}

const VerticalGiftList = ({ cardStyle, items, headline, eyebrow, cta }) => {
  const currentBreakpoint = useCurrentBreakpoint()

  const [ref, inView] = useInView({
    threshold: 0.1,
    unobserveOnEnter: true,
  })

  const theme = useTheme()
  return (
    <Box mb="x4" ref={ref}>
      <Styled.Header mb="x2">
        <SectionIntro
          eyebrow={eyebrow}
          title={headline}
          component={cta ? cta : null}
        />
      </Styled.Header>

      {!!items.length && (
        <Styled.List>
          {items.map((item) => {
            return (
              <Box
                as="li"
                mb="x2"
                key={`vertical-list-item-${item.url_key}`}
                className={inView ? theme.inViewClass : ''}
              >
                <Link to={item.url_key}>
                  <Styled.TextContainer>
                    <Text textStyle="listItemName">{item.name}</Text>
                    <Text
                      mt="x1"
                      color="secondary.greydark"
                      textStyle="listItemDescription"
                      height="3.5rem"
                      overflow="hidden"
                    >
                      {ellipsify(
                        stripHtml(item.description),
                        currentBreakpoint < 3 ? 126 : 80,
                      )}
                    </Text>
                  </Styled.TextContainer>
                  <Styled.ImageContainer cardStyle={cardStyle}>
                    <ImageLazy
                      background="transparent"
                      borderRadius="0.5rem"
                      w="auto"
                      overflow="hidden"
                      src={item.img_url}
                      alt={item.name}
                    />
                  </Styled.ImageContainer>
                </Link>
              </Box>
            )
          })}
        </Styled.List>
      )}
    </Box>
  )
}

const itemProps = PropTypes.shape({
  url_key: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  img_url: PropTypes.string,
})

VerticalGiftList.propTypes = {
  cardStyle: PropTypes.oneOf(['cards']),
  items: PropTypes.arrayOf(itemProps),
  headline: PropTypes.string,
  eyebrow: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  cta: PropTypes.element,
}

VerticalGiftList.defaultProps = {
  cardStyle: 'cards',
}

export default VerticalGiftList
