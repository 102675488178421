import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Text } from '../../components/text'
import { Loader } from '../../components/loader'
import { Row, Col } from '../../components/grid'
import { GiftCard } from '../../components/gift-card'
import { Message } from '@paypalcorp/worldready-react'
import { ResultsHeader } from './ResultsHeader'
import InfiniteScroll from 'react-infinite-scroll-component'

export const FilteredItemList = ({
  Item = Fragment,
  headerMessageID = '',
  show = true,
  basepath = '/',
  filteredItems = [],
}) => {
  const LOAD_INTERVAL = 10 * 4 // 10 rows of 4 cards

  const [lazyItems, setLazyItems] = useState([])

  useEffect(() => {
    if (filteredItems.length)
      setLazyItems([...filteredItems.slice(0, LOAD_INTERVAL)])
  }, [filteredItems])

  const fetchMoreLazyItems = () => {
    // NOTE: Will be replaced by a paginated API
    setLazyItems([
      ...lazyItems,
      ...filteredItems.slice(
        lazyItems.length,
        lazyItems.length + LOAD_INTERVAL,
      ),
    ])
  }

  if (filteredItems.length === 0 || !show) return null

  return (
    <Fragment>
      <ResultsHeader>
        <Text textStyle="h3">
          <Message id={headerMessageID} />
        </Text>
      </ResultsHeader>
      <InfiniteScroll
        dataLength={lazyItems.length}
        next={fetchMoreLazyItems}
        hasMore={lazyItems.length !== filteredItems.length}
        loader={<Loader p="100px 0" />}
        style={{ overflow: 'hidden' }} // hides scrollbar
      >
        <Row py="2">
          {lazyItems.map((item, i) => (
            <Item key={`filtered-item-${i}`} item={item} basepath={basepath} />
          ))}
        </Row>
      </InfiniteScroll>
    </Fragment>
  )
}

FilteredItemList.propTypes = {
  headerMessageID: PropTypes.string.isRequired,
  Item: PropTypes.func.isRequired,
  basepath: PropTypes.string,
  filteredItems: PropTypes.array,
  show: PropTypes.bool,
}

export const CardItem = ({ item, basepath }) => (
  <Col xs={12} sm={6} md={4} lg={3} mb="2rem">
    <GiftCard
      img={item.img_url}
      label={item.name}
      url={`${basepath}${item.url_key}`}
    />
  </Col>
)

CardItem.propTypes = {
  item: PropTypes.object.isRequired,
  basepath: PropTypes.string.isRequired,
}
